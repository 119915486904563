//Hoja de estilos
import '../src/styles/style.css'

//componente para las rutas
import { AppRouter } from "./router/AppRouter";

function App() {
  return (
  <>
    <AppRouter />
  </>    
  );
}

export default App;
